import React from 'react';

const ContactMe = () => {
    return (
        <div className="text-grid">
              <div className="text-grid-main">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <div className="main-title text-center wow fadeIn">
                        <h3>Contact Me</h3>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typeLorem Ipsum is simply dummy text of the printing
                          and type
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    );
}

export default ContactMe;
