import React from "react";
import Banner from "../Sections/Banner";
import ContactMe from "../Sections/ContactMe";
import ContactSec from "../Sections/ContactSec";

const ContactUs = () => {
  return (
    <div className="full-page">
      <Banner Title={"Contact US"} />
      <br/>
      <br/>
      <br/>
      <br/>
      <ContactMe/>
      <ContactSec/>
       
    </div>
  );
};

export default ContactUs;
