import React from 'react';
import Imgabout from '../Images/mel-fitness-coach-shutterstock.jpg';
import WhatWeDo from '../Sections/WhatWeDo';
import Banner from '../Sections/Banner';

const About = () => {
    return (
        <div className='full-page'>
         <Banner Title={'ABOUT COACHES WINDOW'}/> 

    <div className='row-about'>
        <div className='colItem'>
        
        <img src={Imgabout} />

        </div>
        <div className='colItem des'>
        <h1 >ABOUT COACHES WINDOW </h1>
            <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>
            <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>
            <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>



</div>
    </div>

    <WhatWeDo/>
        </div>
    );
}

export default About;
