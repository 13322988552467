import React from 'react';

const WhatWeDo = () => {
    return (
        <div id="about">
          <div className="about-content">
            <div className="love-grid text-center">
              <div className="container">
                <div className="row" style={{marginTop: 42}}>
                  <div className="col-md-12">
                    <div className="main-title text-center wow fadeIn">
                      <h3>What WE Do</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                      </p>
                    </div>

            <div className="row newzz">
      <div className="col-12 col-sm-12 col-lg-4 col-xxl-4 col-md-4">
      <img
                        src="https://i.pinimg.com/originals/f4/cf/ec/f4cfeca190a7ecc9ede70ba7567ab7fe.jpg"
                        alt="sanajit-jana"
                        className="about-img wow fadeIn new"
                      />
      </div>
      <div className="col-12 col-sm-12 col-lg-4 col-xxl-4 col-md-4">
      <img
                        src="https://avatars.mds.yandex.net/i?id=5b49eebeeceb2ec07f34ac62594c0576974291c7-9227708-images-thumbs&n=13"
                        alt="sanajit-jana"
                        className="about-img wow fadeIn new"
                      />
      </div>
      <div className="col-12 col-sm-12 col-lg-4 col-xxl-4 col-md-4">
      <img
                        src="assets/img/specializzazioni-wepa-science-img-wallpaper-2400px.jpg"
                        alt="sanajit-jana"
                        className="about-img wow fadeIn new"
                      />
      </div>
            </div>

                  </div>
                </div>
                

                <div className="row love-row wow fadeIn">
                  <div className="col-md-3 col-sm-6">
                    <div className="love-details" data-wow-delay=".1s">
                      <i
                        className="fa fa-pencil-square-o love-icon"
                        aria-hidden="true"
                      ></i>
                      <h3>Training Name</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>Lorem Ipsum is simply dummy text of the printing</p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <div className="love-details" data-wow-delay=".3s">
                      <i
                        className="fa fa-file-image-o love-icon"
                        aria-hidden="true"
                      ></i>
                      <h3>Training Name</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>Lorem Ipsum is simply dummy text of the printing</p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <div className="love-details" data-wow-delay=".2s">
                      <i
                        className="fa fa-globe love-icon"
                        aria-hidden="true"
                      ></i>
                      <h3>Training Name</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>Lorem Ipsum is simply dummy text of the printing</p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <div className="love-details" data-wow-delay=".4s">
                      <i className="fa fa-cog love-icon" aria-hidden="true"></i>
                      <h3>Training Name</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>Lorem Ipsum is simply dummy text of the printing</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

           
          </div>
        </div>
    );
}

export default WhatWeDo;
