import React from 'react';
import Banner from '../Sections/Banner';

const CoachesAndTrainers = () => {
    return (
        <>
        
        <Banner Title={"Coaches And Trainers"} />
        
        <div id="service">
          <div className="service-content">
            <div className="service-grid text-center">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="main-title text-center wow fadeIn">
                      <h3>Coaches And Trainers</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        types
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row love-row wow fadeIn">
                  
                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                          src="https://media.cnn.com/api/v1/images/stellar/prod/230901125155-01-jorge-vilda-luis-rubiales.jpg?c=16x9&q=h_720,w_1280,c_fill"
                          alt="design-development"
                        />
                      </div>
                      <div className="service-bottom"> 
                        <h3>Coach Name</h3>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesLorem Ipsum is simply dummy text of the printing
                          and types
                        </p>
                      </div>
                    </div>
                  </div> 

                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                       src="https://s3-us-west-1.amazonaws.com/contentlab.studiod/getty/e23cda61f9014790a9c0e6459160e547"
                          alt="design-development"
                        />
                      </div>
                      <div className="service-bottom"> 
                        <h3>Trainer Name</h3>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesLorem Ipsum is simply dummy text of the printing
                          and types
                        </p>
                      </div>
                    </div>
                  </div> 

                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                          src="https://static.tildacdn.com/tild6632-3337-4532-b063-633462386264/302406_5e9688791c865.jpeg"
                          alt="design-development"
                        />
                      </div>
                      <div className="service-bottom"> 
                        <h3>Coach Name</h3>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesLorem Ipsum is simply dummy text of the printing
                          and types
                        </p>
                      </div>
                    </div>
                  </div> 

                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                          src="https://content.api.news/v3/images/bin/52f2d203eca4199762c277451c733bc1"
                          alt="design-development"
                        />
                      </div>
                      <div className="service-bottom"> 
                        <h3>Trainer Name</h3>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesLorem Ipsum is simply dummy text of the printing
                          and types
                        </p>
                      </div>
                    </div>
                  </div> 

                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                          src="https://www.sott.net/image/s22/441819/full/Personal_Trainer.jpg"
                          alt="design-development"
                        />
                      </div>
                      <div className="service-bottom"> 
                        <h3>Coach Name</h3>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesLorem Ipsum is simply dummy text of the printing
                          and types
                        </p>
                      </div>
                    </div>
                  </div> 

                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                          src="https://s3-us-west-1.amazonaws.com/contentlab.studiod/getty/e23cda61f9014790a9c0e6459160e547"
                          alt="design-development"
                        />
                      </div>
                      <div className="service-bottom"> 
                        <h3>Trainer Name</h3>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesLorem Ipsum is simply dummy text of the printing
                          and types
                        </p>
                      </div>
                    </div>
                  </div> 

                </div>
              </div>
            </div> 
          </div>
        </div>
        </>
    );
}

export default CoachesAndTrainers;
