import React from 'react';

const EmailMap = () => {
    return (
        <div className="row contact-info-row text-center wow fadeIn">
        <div className="col-md-3 col-sm-6 contact-colamn">
          <div className="contact-col-info" data-wow-delay=".2s">
            <i
              className="fa fa-map-marker contact-icon"
              aria-hidden="true"
            ></i>
            <h3>Address</h3>
            <p>Lorem Ipsum is simply dummy text of the printing and type</p>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 contact-colamn">
          <div className="contact-col-info" data-wow-delay=".4s">
            <i
              className="fa fa-envelope contact-icon"
              aria-hidden="true"
            ></i>
            <h3>Email</h3>
            <a
              href="mailto:contact.sanajitjana@gmail.com"
              style={{
                color: "black",
                textDecoration: "none",
                fontSize: "17px",
              }}
            >
              test@gmail.com
            </a>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 contact-colamn">
          <div className="contact-col-info" data-wow-delay=".6s">
            <i
              className="fa fa-mobile contact-icon"
              aria-hidden="true"
            ></i>
            <h3>Phone</h3>
            <a
              href="tel:8372876775"
              style={{
                color: "black",
                textDecoration: "none",
                fontSize: "17px",
              }}
            >
              +91 8372876775
            </a>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 contact-colamn">
          <div className="contact-col-info" data-wow-delay=".6s">
            <i
              className="fa fa-television contact-icon"
              aria-hidden="true"
            ></i>
            <h3>Portfolio</h3>
            <p>Link......</p>
          </div>
        </div>
      </div>
    );
}

export default EmailMap;
