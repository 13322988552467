import React from 'react';
import Banner from '../Sections/Banner';

const SignUp = () => {
    return (
<>
      <Banner Title={'OUR SUBSCRIPTIONS'}/> 
      <div class="containesasr">
      <div class="pricing-table">
          <div class="pricing-table-header">
              <h1>Standard</h1>
          </div>
          
          <div class="pricing-table-content">
              <ul>
                  <li><strong>Title</strong> Lorem Ipsum</li>
                  <li><strong>Title</strong> Lorem Ipsum</li>
                  <li><strong>Title</strong> Lorem Ipsum</li>
                  <li><strong>Title</strong> Lorem Ipsum</li>
              </ul>
          </div>
          
          <div class="pricing-table-footer">
              <h2><sup>$</sup>9.99</h2>
              <p>per month</p>
              <a href="#">Sign Up</a>
          </div>
      </div>
      
      <div class="pricing-table featured">
          <div class="pricing-table-header">
              <h1>Professional</h1>
          </div>
          
          <div class="pricing-table-content">
              <ul>
                  <li><strong>Title</strong> Lorem Ipsum</li>
                  <li><strong>Title</strong> Lorem Ipsum</li>
                  <li><strong>Title</strong> Lorem Ipsum</li>
                  <li><strong>Title</strong> Lorem Ipsum</li>
              </ul>
          </div>
          
          <div class="pricing-table-footer">
              <h2><sup>$</sup>19.99</h2>
              <p>per month</p>
              <a href="#">Sign Up</a>
          </div>
      </div>
      
      <div class="pricing-table">
          <div class="pricing-table-header">
              <h1>Ultimate</h1>
          </div>
          
          <div class="pricing-table-content">
              <ul>
                  <li><strong>Title</strong> Lorem Ipsum</li>
                  <li><strong>Title</strong> Lorem Ipsum</li>
                  <li><strong>Title</strong> Lorem Ipsum</li>
                  <li><strong>Title</strong> Lorem Ipsum</li>
              </ul>
          </div>
          
          <div class="pricing-table-footer">
              <h2><sup>$</sup>29.99</h2>
              <p>per month</p>
              <a href="#">Sign Up</a>
          </div>
      </div>
      
  </div>
  </>
    );
}

export default SignUp;
