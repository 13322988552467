import React from "react";
import "./App.css";

// import Router
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// import Component
import Header from "./component/Header";
import Footer from "./component/Footer";
import ScrollToTop from "./component/ScrollToTop";

// import Pages
import Home from "./Pages/Home";
import ReactHooks from "./Pages/ReactHooks";
import UiUx from "./Pages/UiUx";
import FormExample from "./Pages/FormExample";
import About from "./Pages/About";
import Blog from "./Pages/Blog";
import ContactUs from "./Pages/ContactUs";
import CoachesAndTrainers from "./Pages/CoachesAndTrainers";
import SignUp from "./Pages/SignUp";
import DashMain from "./Dashboard/main";
import Calender from "./Pages/Calender";
import Dashboard from "./Pages/Dashboard";
import ManageAthletes from "./Pages/ManageAthletes";
import ATP from "./Pages/ATP";

function App() {
  return (
    <React.StrictMode>
      <Router>
        {1 === 1 ? <Header /> : null}

        <ScrollToTop>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/Sign-Up" component={SignUp} />
            <Route exact path="/About" component={About} />
            <Route exact path="/blogs" component={Blog} />
            <Route exact path="/Contact" component={ContactUs} />
            <Route
              exact
              path="/Coaches-And-Trainers"
              component={CoachesAndTrainers}
            />
            <Route exact path="/react-hooks" component={ReactHooks} />
            <Route exact path="/ui-ux" component={UiUx} />
            <Route exact path="/form-example" component={FormExample} />
            
            {/* <Route exact path="/" component={DashMain} />
            <Route exact path="/Home" component={DashMain} />
            <Route exact path="/Calender" component={Calender} />
            <Route exact path="/Dashboard" component={Dashboard} />
            <Route exact path="/ManageAthletes" component={ManageAthletes} />
            <Route exact path="/ATP" component={ATP} /> */}
            

          </Switch>
        </ScrollToTop>

        {1 === 1 ? <Footer /> : null}
      </Router>
    </React.StrictMode>
  );
}

export default App;
