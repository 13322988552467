import React from 'react';
import EmailMap from './EmailMap';
import Map from './Map';

const ContactSec = () => {
    return (
        <div className="contact-grid">
        <div className="contact-form-details wow fadeIn">
          <div className="container">
            <div className="row contact-row">
              <div className="col-md-8 col-md-offset-2 col-sm-12 col-sm-offset-1 contact-col">
                <h3>Write Your Message Here</h3>
                <div className="contact-form">
                  <form
                    action="https://herokuapp.us6.list-manage.com/subscribe/post?u=870e1b447d1f93893227a9c52&amp;id=85d6c939a5"
                    method="post"
                    id="mc-embedded-subscribe-form contactForm"
                    name="mc-embedded-subscribe-form"
                    className="contact-form shake validate"
                    target="_blank"
                    data-toggle="validator"
                    novalidate
                  >
                    <div className="form-group">
                      <div className="controls">
                        <input
                          type="text"
                          name="FLNAME"
                          id="mce-FLNAME"
                          className="required form-control"
                          placeholder="Your Name"
                          required
                          data-error="Please enter your name"
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="controls">
                        <input
                          type="email"
                          className="email required form-control"
                          name="EMAIL"
                          id="mce-EMAIL"
                          placeholder="Your Email"
                          required
                          data-error="Please enter your email"
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="controls">
                        <input
                          type="phone"
                          name="PHONE"
                          className="phone form-control"
                          id="phone mce-PHONE"
                          placeholder="Your Phone"
                          required
                          data-error="Please enter your phone"
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="controls">
                        <textarea
                          id="message mce-MESSAGE"
                          rows="7"
                          placeholder="Your Massage"
                          name="MESSAGE"
                          className="required form-control"
                          required
                          data-error="Please enter your message"
                        ></textarea>
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>
                    <div id="mce-responses" class="clear">
                      <div
                        class="response"
                        id="mce-error-response"
                        style={{ display: "none" }}
                      ></div>
                      <div
                        class="response"
                        id="mce-success-response"
                        style={{ display: "none" }}
                      ></div>
                    </div>
                    <div
                      style={{ position: "absolute", left: "-5000px" }}
                      aria-hidden="true"
                    >
                      <input
                        type="text"
                        name="b_870e1b447d1f93893227a9c52_85d6c939a5"
                        tabindex="-1"
                        value=""
                      />
                    </div>
                    <button
                      type="submit"
                      value="Subscribe"
                      name="subscribe"
                      id="submit mc-embedded-subscribe"
                      className="btn btn-success"
                      style={{color: 'white'}}
                    >
                      Send Message
                    </button>
                    <div
                      id="msgSubmit"
                      className="h3 text-center hidden"
                    ></div>
                    <div className="clearfix"></div>
                  </form>
                </div>
              </div>
            </div>
            <EmailMap/>
          </div>
         <Map/>
        </div>
      </div>

    );
}

export default ContactSec;
