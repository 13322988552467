import React from 'react';

const Banner = ({Title}) => {
    return (
        <div class="banner">
        <h1 class="banner-title"> {Title}</h1>
    </div>
    );
}

export default Banner;
